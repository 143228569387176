import {
    Avatar,
    Box, capitalize,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar, useMediaQuery,
    useTheme
} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import * as React from "react";
import {adminItems, isNormalPage} from "../admin-items";
import {useNavigate} from "react-router-dom";
import {Close, Inbox, Mail} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import './MenuDrawer.css';
import {useMsal} from "@azure/msal-react";
import MenuUser from "./MenuUser";
import {useState} from "react";
import logo from '../../assets/MoodbitLogo.png';
import logo2 from '../../assets/MoodbitLogo2.png';
/*import logo from '../../assets/Full-Logo-Yellow-Bars.jpg';
import logo2 from '../../assets/CCSshort_logo-1.jpg';*/
import {addUserThunk, editUserThunk, getUserWithEmailThunk} from "../../redux/users/users.thunk";
import {updateCountry} from "../../redux/users/users.actions";
import {connect} from "react-redux";
import {isCountries} from "../../helpers/region";
import Unauthenticated from "../../azure_auth/Unauthenticated";
import MenuDrawerItem from "./MenuDrawerItem";
import { toastr } from "react-redux-toastr";

const drawerWidth = 240;

const styles = {
    logo2: {
        width: '52px',
        height: '100%',
        cursor: 'pointer',
        // localStorage.getItem('gtp-country') === 'Bolivia' ? 0 : 1
    },
    logo: {
        width: '180px',
        height: '100%',
        cursor: 'pointer',
        // opacity: localStorage.getItem('gtp-country') === 'Bolivia' ? 0 : 1
    }
}

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('xs')]: {
        width: 0,
    },
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const MenuDrawer = ({...props}) => {
    console.log("DRAWER PROPS", props)
    const {handleDrawerOpen, handleDrawerClose, open, smallOpen} = props;
    const [anchorElUser, setAnchorElUser] = useState(null);
    const pages = adminItems(props.user);
    const theme = useTheme();

    const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
    const smallToMid = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

    if (greaterThanMid) {
        console.warn("Greater than mid");
    }
    if (smallToMid) {
        console.warn("Between small to mid");
    }
    if (lessThanSmall) {
        console.warn("Less than small")
    }

    // const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const {accounts, instance} = useMsal();

    /*styles.logo.opacity = props.country === 'Bolivia' ? 0 : 1;
    styles.logo2.opacity = props.country === 'Bolivia' ? 0 : 1;*/

    const onRoute = (url) => {
        navigate(url);
//         handleDrawerToggle();
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = async (value = '') => {
        console.log("logout 1:");
        setAnchorElUser(null);
        try {
            if (value.name === 'Logout') {
                try {
                    await instance.logoutRedirect({ postLogoutRedirectUri: "/" });
                    localStorage.removeItem('slackTeamId');
                } catch (redirectError) {
                    console.warn("Redirect Logout Error:", redirectError);
                    toastr.error(`Something went wrong, please reload the page to try again`);
                    try {
                        await instance.logoutPopup({ postLogoutRedirectUri: "/" });
                        localStorage.removeItem('slackTeamId');
                        window.location.reload();

                    } catch (popupError) {
                        console.error("Popup Logout Error:", popupError);
                        toastr.error(`Something went wrong, please reload the page to try again`);
                    }
                }
            } else {
                if (value !== '') navigate(value.route);
            }
        } catch (error) {
            console.error("General Logout Error:", error);
            toastr.error(`Something went wrong, please reload the page to try again`);
        }
    };
    
    const valuePage = false;//isNormalPage();
    console.log("--------- value page: ",valuePage)
    console.log("*********** extra page information: ", isNormalPage())
    const openFunc = () => {
        return lessThanSmall ? smallOpen : open
    }
    return (
        <Box>
            <Drawer variant="permanent" open={openFunc()}>
                <Box>
                    {/*<Toolbar className="p-0" sx={{display: {xs: 'none', sm: 'block'}}}/>*/}
                    <DrawerHeader sx={{display: {xs: 'none', sm: 'flex'}, justifyContent: 'center'}}>
                        {!openFunc() ?
                    <img style={{...styles.logo2, opacity: isCountries(props.country) ? 0 : 1}} src={logo2} alt="" onClick={() => onRoute('/')}/> :
                    <img style={{...styles.logo, opacity: isCountries(props.country) ? 0 : 1}} src={logo} alt="" onClick={() => onRoute('/')}/>}

                        {/*<IconButton size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={open ? handleDrawerClose : handleDrawerOpen}
                                    color="inherit">
                            {open ? <Close/> : <MenuIcon/>}
                        </IconButton>*/}
                    </DrawerHeader>
                    <Divider sx={{display: {xs: 'none', sm: 'block'}}}/>
                    <List sx={{maxHeight: 'calc(100vh - 140px)', overflowY: 'auto'}} className="scroll-container">
                        {pages?.map((item, index) => (
                            <MenuDrawerItem item={item} index={index} openFunc={() => openFunc}/>
                        ))}
                    </List>
                </Box>
                <Box>
                    {/*<Toolbar className="p-0" sx={{display: {xs: 'block', sm: 'none'}}}/>*/}
                    <DrawerHeader sx={{display: {xs: 'flex', sm: 'none'}}} className="justify-content-center">
                            <img style={styles.logo} src={logo} alt="" onClick={() => onRoute('/')}/>

                        {/*<IconButton size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={open ? handleDrawerClose : handleDrawerOpen}
                                    color="inherit">
                            {open ? <Close/> : <MenuIcon/>}
                        </IconButton>*/}
                    </DrawerHeader>
                    <Divider sx={{display: {xs: 'block', sm: 'none'}}}/>
                    <List>
                        <ListItem disablePadding sx={{display: 'block'}}>
                            <ListItemButton
                                onClick={handleOpenUserMenu}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: openFunc() ? 'initial' : 'center',
                                    px: 2.5,
                                }}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: openFunc() ? 1 : 'auto',
                                        justifyContent: 'center',
                                    }}>
                                    <IconButton size="large" sx={{p: 0}}>
                                        <Avatar alt={accounts[0]?.idTokenClaims?.extension_firstName?accounts[0].idTokenClaims?.extension_firstName : ""}
                                                src={`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/download/${props.user?.photo}`}/>

                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText primary={<b className="text-small">{capitalize(props.user?.firstName || '') + ' ' + capitalize(props.user?.lastName || '')}</b>} sx={{opacity: openFunc() ? 1 : 0}}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            {props.user ? <MenuUser anchorElUser={anchorElUser}
                       user={props.user}
                       handleCloseUserMenu={handleCloseUserMenu}/> : null}
        </Box>

    )
}

const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        country: state.users.country,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuDrawer);
