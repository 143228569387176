import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import axios from 'axios';
import pptxgen from 'pptxgenjs';
import { LoadingButton } from '../graphics/LoadingButton';
import { PowerPointIcon } from './PowerPointIcon';
import { toastr } from "react-redux-toastr";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress, Typography, Box } from "@mui/material";

import theme1TitleImage from "./backgrounds/theme1-title.png";
import theme1TextSmallImage from "./backgrounds/theme1-textsmall.png";
import theme1TextLargeImage from "./backgrounds/theme1-textlarge.png";
import theme1Chart00Image from "./backgrounds/theme1-chart00.png";

import theme2TitleImage from "./backgrounds/theme2-title.jpg";
import theme2TextSmallImage from "./backgrounds/theme2-textsmall.jpg";
import theme2TextLargeImage from "./backgrounds/theme2-textlarge.jpg";
import theme2Chart00Image from "./backgrounds/theme2-chart00.jpg";

import theme3TitleImage from "./backgrounds/theme3-title.jpg";
import theme3TextSmallImage from "./backgrounds/theme3-textsmall.jpg";
import theme3TextLargeImage from "./backgrounds/theme3-textlarge.jpg";
import theme3Chart00Image from "./backgrounds/theme3-chart00.jpg";
import { Card } from 'react-bootstrap';

const themes = {
    theme1: {
        title: theme1TitleImage,
        textSmall: theme1TextSmallImage,
        textLarge: theme1TextLargeImage,
        chart00: theme1Chart00Image,
    },
    theme2: {
        title: theme2TitleImage,
        textSmall: theme2TextSmallImage,
        textLarge: theme2TextLargeImage,
        chart00: theme2Chart00Image,
    },
    theme3: {
        title: theme3TitleImage,
        textSmall: theme3TextSmallImage,
        textLarge: theme3TextLargeImage,
        chart00: theme3Chart00Image,
    }
}


function extractLabelsAndValues(data) {
    const labels = [];
    const values = [];

    for (let j = 0; j < data['sql_json'].length; j++) {

        labels.push(data['sql_json'][j][data['graphic'].properties[0]]);
        values.push(data['sql_json'][j][data['graphic'].properties[1]]);
    }

    return { labels, values };
}

const GenerateSlides = ({ inputData, ...props }) => {
    const [responseSlides, setResponseSlides] = useState([]);
    const [isLoadingPresentation, setIsLoadingPresentation] = useState(false);
    const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);

    const handleGeneratePresentation = async (event, themeName) => {

        const presentationData = [];

        console.log("INPUT DATA:", inputData);
        console.log("THEME NAME:", themeName);
        for (let i = 0; i < inputData.length; i++) {
            const item = inputData[i];
            let userContent = "hi";
            let adminContent = "hi, thanks";
            let index = i;
            // if(item[1].sql_json !== undefined){
            userContent = item[0];
            let subText = item[1].answer.substring(1, 1000);
            adminContent = subText + "\n" + item[1].insight;
            // }

            // we format the messages to send to the backend
            presentationData.push({ content: userContent, role: "user", index: index * 2 });
            presentationData.push({ content: adminContent, role: "assistant", index: index * 2 + 1 });

        }
        setIsLoadingPresentation(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACK_HR_AGENT}/generate-slides`, presentationData);
            const jsonSlides = response.data;

            setResponseSlides(jsonSlides);
            const now = new Date();
            const dateStr = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
            const timeStr = `${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now.getSeconds().toString().padStart(2, '0')}`;
            const fileName = `moodbit_copilot_${dateStr}_${timeStr}`;

            await exportToSlides(jsonSlides, fileName, themeName);
        } catch (error) {
            console.error('Error in the backend when generating the slide content:', error);
            toastr.error(`Something went wrong, please reload the page to try again`);
        } finally {
            setIsLoadingPresentation(false);
        }
    };

    const exportToSlides = async (jsonSlides, presentationName, themeName) => {
        try {
            console.log("THEMES:", themes);
            const theme = themes[themeName];
            console.log("THEME:", theme);

            let pptx = new pptxgen();
            pptx.layout = "LAYOUT_WIDE";

            // TITLE_SLIDE
            pptx.defineSlideMaster({
                title: "TITLE_SLIDE",
                background: { path: theme.title },
            });

            // TEXTSMALL_SLIDE
            pptx.defineSlideMaster({
                title: "TEXTSMALL_SLIDE",
                background: { path: theme.textSmall },
            });

            // TEXTLARGE_SLIDE
            pptx.defineSlideMaster({
                title: "TEXTLARGE_SLIDE",
                background: { path: theme.textLarge },
            });

            // CHART00_SLIDE
            pptx.defineSlideMaster({
                title: "CHART00_SLIDE",
                background: { path: theme.chart00 },
            });

            console.log("jsonSlides:", jsonSlides);

            for (let i = 0; i < jsonSlides.length; i++) {
                const jsonSlide = jsonSlides[i];
                let slide = "";
                if (i === 0 || i === jsonSlides.length - 1) {
                    slide = pptx.addSlide({ masterName: "TITLE_SLIDE" });
                    slide.addText(jsonSlide.title, {
                        x: "10%",
                        y: "30%",
                        w: "80%",
                        h: "40%",
                        align: "center",
                        fontSize: 64,
                        color: "EEEEEE",
                        fontFace: "Arial Rounded MT Bold",
                    });
                }
                else if (jsonSlide.index.length > 0 && i > 1 && i < (jsonSlides.length - 2) && inputData.length > (jsonSlide.index[0] - 1) / 2) {
                    let ind = -1;
                    for (let j = 0; j < jsonSlide.index.length; j++) {

                        if (inputData[(jsonSlide.index[j] - 1) / 2][1].graphic === undefined) {
                            continue;
                        } else {
                            ind = (jsonSlide.index[j] - 1) / 2;
                            break;
                        }
                    }

                    if (ind === -1) {
                        let content = Array.isArray(jsonSlide.content) ? jsonSlide.content.join(' ') : jsonSlide.content;
                        slide = pptx.addSlide({ masterName: "CHART00_SLIDE" });
                        const randomNumber = Math.random();
                        if (randomNumber <= 0.50) {
                            // Texto a la izquierda, gráfico a la derecha
                            slide.addText(jsonSlide.title, {
                                x: "20%",
                                y: "10%",
                                w: "60%",
                                h: "20%",
                                align: "center",
                                fontSize: 32,
                                fontFace: "Arial Rounded MT Bold",
                                color: pptx.SchemeColor.text1,
                            });
                            slide.addText(content, {
                                x: "10%",
                                y: "20%",
                                w: "38%",
                                h: "60%",
                                align: "center",
                                fontSize: 14,
                                fontFace: "Arial Rounded MT",
                            });
                            if (jsonSlide.chart) {
                                slide.addChart(pptx.ChartType.line, jsonSlide.chart.data, {
                                    x: 7,
                                    y: 2.5,
                                    w: 5,
                                    h: 4,
                                });
                            }
                        } else {
                            // Texto a la derecha, gráfico a la izquierda
                            slide.addText(jsonSlide.title, {
                                x: "20%",
                                y: "10%",
                                w: "60%",
                                h: "20%",
                                align: "center",
                                fontSize: 32,
                                fontFace: "Arial Rounded MT Bold",
                                color: pptx.SchemeColor.text1,
                            });
                            slide.addText(content, {
                                x: "55%",
                                y: "20%",
                                w: "38%",
                                h: "60%",
                                align: "center",
                                fontSize: 14,
                                fontFace: "Arial Rounded MT",
                            });
                            if (jsonSlide.chart) {
                                slide.addChart(pptx.ChartType.line, jsonSlide.chart.data, {
                                    x: 1,
                                    y: 2.5,
                                    w: 5,
                                    h: 4,
                                });
                            }
                        } 
                    }
                }
                else {
                    if (jsonSlide.content !== undefined) {
                        let content = Array.isArray(jsonSlide.content) ? jsonSlide.content.join(' ') : jsonSlide.content;
                        if (content.length < 700) {
                            slide = pptx.addSlide({ masterName: "TEXTSMALL_SLIDE" });
                                slide.addText(jsonSlide.title, {
                                    x: "20%",
                                    y: "10%",
                                    w: "60%",
                                    h: "20%",
                                    align: "center",
                                    fontSize: 32,
                                    fontFace: "Arial Rounded MT Bold",
                                    color: pptx.SchemeColor.text1,
                                });
                                slide.addText(content, {
                                    x: "17%",
                                    y: "20%",
                                    w: "65%",
                                    h: "60%",
                                    align: "center",
                                    fontSize: 20,
                                    fontFace: "Arial Rounded MT",
                                });      
                        } else {
                            slide = pptx.addSlide({ masterName: "TEXTLARGE_SLIDE" });
                            slide.addText(jsonSlide.title, {
                                x: "10%",
                                y: "25%",
                                w: "80%",
                                h: "15%",
                                align: "center",
                                fontSize: 32,
                                fontFace: "Arial Rounded MT Bold",
                                color: pptx.SchemeColor.text1,
                            });
                            slide.addText(content, {
                                x: "17%",
                                y: "40%",
                                w: "80%",
                                h: "70%",
                                align: "center",
                                fontSize: 17,
                                fontFace: "Arial Rounded MT",
                            });
                        }
                    } else {
                        slide = pptx.addSlide({ masterName: "TITLE_SLIDE" });
                        slide.addText(jsonSlide.title, {
                            x: "10%",
                            y: "15%",
                            w: "80%",
                            h: "15%",
                            align: "center",
                            fontSize: 32,
                            fontFace: "Arial Rounded MT Bold",
                            color: pptx.SchemeColor.text1,
                        });
                    }
                }
                /*if (jsonSlide.chart) {
                    slide.addChart(pptx.ChartType.line, jsonSlide.chart.data, {
                        x: 7,
                        y: 2.5,
                        w: 5,
                        h: 4,
                    });
                }*/
            }
            await pptx.writeFile({ fileName: `${presentationName}.pptx` });
        } catch (err) {
            console.error('Error when generating the slide:', err);
            toastr.error(`Something went wrong, please reload the page to try again`);
        }
    };

    const toggleThemeMenu = () => {
        setIsThemeMenuOpen(value => !value);
    }

    return (
        <>
            {
                props.inMenu ? (
                    <MenuItem onClick={toggleThemeMenu} disabled={isLoadingPresentation}>
                        <ListItemIcon>
                            {isLoadingPresentation ? (
                                <CircularProgress size={20} />
                            ) : (
                                <PowerPointIcon sx={{ width: 25, height: 25 }} />
                            )}
                        </ListItemIcon>
                        <ListItemText>Export PPT</ListItemText>
                    </MenuItem>
                ) : (
                    <div>
                        <LoadingButton
                            isLoading={isLoadingPresentation}
                            onClick={handleGeneratePresentation}
                        >
                            <PowerPointIcon sx={{ width: 25, height: 25 }} />
                        </LoadingButton>
                    </div>
                )
            }
            {
                isThemeMenuOpen &&
                createPortal(
                    <Box
                        sx={{
                            position: 'absolute',
                            left: { xs: '5%', sm: '15%', md: '30%', lg: '35%' },
                            top: { xs: '10%', sm: '15%', md: '20%' },
                            zIndex: 9999,
                            background: '#FFFFFF',
                            p: 3,
                            borderRadius: '8px',
                            width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' },
                            boxShadow: 3,
                            maxWidth: '500px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold', px: 2, py: 1, textAlign: 'center' }}>Themes</Typography>
                        <Typography sx={{ px: 2, pb: 2, textAlign: 'center' }}>Use one of our themes below.</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 2,
                                flexWrap: 'wrap'
                            }}
                        >
                            {[{ theme: 'theme1', img: theme1TitleImage }, { theme: 'theme2', img: theme2TitleImage }, { theme: 'theme3', img: theme3TitleImage }].map(({ theme, img }) => (
                                <Card
                                    key={theme}
                                    sx={{
                                        cursor: 'pointer',
                                        p: 2,
                                        borderRadius: '8px',
                                        width: { xs: '90%', sm: '45%', md: '30%' },
                                        height: 'auto',
                                        transition: 'transform 0.3s ease, background-color 0.3s ease',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:hover': {
                                            backgroundColor: '#f0f0f0',
                                            transform: 'scale(1.05)'
                                        }
                                    }}
                                    onClick={(event) => handleGeneratePresentation(event, theme)}
                                >
                                    <img
                                        src={img}
                                        alt={theme}
                                        style={{
                                            width: '100%',
                                            maxWidth: '150px',
                                            height: 'auto',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                            transition: 'transform 0.3s ease',
                                        }}
                                        onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                                        onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                    />
                                    <Typography textAlign='center' sx={{ mt: 1 }}>{theme}</Typography>
                                </Card>
                            ))}
                        </Box>
                    </Box>,
                    document.body
                )
            }
        </>
    );
};

export default GenerateSlides;
