import {Drawer, Skeleton, SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";
import {useState} from "react";
import googleLogo from "../../../../../assets/GoogleDriveLogo.png";
import onedriveLogo from "../../../../../assets/OneDrive-Logo.png";
import {Plagiarism, RuleFolder} from "@mui/icons-material";
import OnedriveFolderIndex from "../../../../integrations/one-drive/onedrive-folder-index";
import {
    deleteGoogleDriveFolderFilesService, deleteOneDriveFolderFilesService,
    getFoldersGoogleDriveService,
    getFoldersOneDriveService,
    getOnedriveTokenService, loadGoogleDriveFolderFilesService,
    loadOneDriveFolderFilesService, refreshTokenGoogleDriveService
} from "../../../../../services/drive-hr.service";
import {toastr} from "react-redux-toastr";
import {
    deleteDriveUserThunk,
    deleteGoogleDriveUserThunk,
    updateDriveUserThunk,
    updateIndexUserThunk
} from "../../../../../redux/users/users.thunk";
import {connect} from "react-redux";
import GoogleDriveFolderIndex from "../../../../integrations/google-drive/googledrive-folder-index";


const DriveListIndex = (props) => {
    const [loading, setLoading] = useState(false);
    const [showFolders, setShowFolders] = useState(false);
    const [integration, setIntegration] = useState(null);

    const [odToken, setOdToken] = useState(null);
    const [oneDriveFolders, setOneDriveFolders] = useState([]);

    const [gdToken, setGdToken] = useState({});
    const [googleDriveFolders, setGoogleDriveFolders] = useState([]);

    /******** GOOGLE DRIVE **********/

    const handlerGoogleCallbackMessage = async () => {

        try {
            setLoading(true)
            setShowFolders(true);
            setIntegration('googledrive');

            let googleDriveIndex = null;
            props.user.indexList.forEach((el) => { if(el.drive === 'googledrive') googleDriveIndex = el; });
            const gEmail = props.user.googleAccounts[0]?.email;
            if (!googleDriveIndex || !gEmail) {
                toastr.warning("No Integration found", "First you need to integrate a Google Drive account")
                return;
            }
            let token = googleDriveIndex.token;
            let data = await refreshTokenGoogleDriveService(token, gEmail);

            if (!data) {
                toastr.warning("Session Expired", "Go to integrations and integrate Google Drive again")
                return; // ignore from another origin
            }
            console.log(data);

            let loadFolders = false;
            if (props.user.googleAccounts) {
                if(props.user.googleAccounts?.length > 0 && data.email === props.user.googleAccounts[0].email) {
                    loadFolders = true;
                } else {
                    toastr.warning("No Integration found", "First you need to integrate a Google Drive account")
                    return;
                }
            } else {
                toastr.warning("No Integration found!", "First you need to integrate a Google Drive account")
                return;
            }
            if (loadFolders) {
                setGdToken(data);

                let folders = await getFoldersGoogleDriveService(data.token, props.user.email)
                console.log("folders: ", folders)
                setGoogleDriveFolders(folders);
                setLoading(false);

            }
        } catch (e) {
            console.log(e)
            toastr.error(`Something went wrong, try again`);
        }
        setLoading(false);
    }

    const loadGoogleDriveFolderFiles = async (folder_id, mime_type) => {
        setLoading(true)
        try {
            //exist user and index
            // const response = await loadGoogleDriveFolderFilesService(gdToken.token, folder_id)
            const response = await loadGoogleDriveFolderFilesService(gdToken.token, folder_id, mime_type, props.user.email)
            console.log(response);
            //TODO: control user not authenticated
            let email_registed_before = false;
            for (let i = 0; i < props.user.indexList.length; i++) {
                let index = props.user.indexList[i]
                console.log(
                    index
                )
                if (index.collection_id === `${props.user.email}-${gdToken.email}-googledrive`)
                    email_registed_before = true;
            }
            if (email_registed_before == false) {
                let index_info = {
                    "googleAccount": {
                        "id": gdToken.email,
                        "email": gdToken.email,
                        "token": gdToken.token,
                        "exp_date": gdToken.expiry,
                    }
                }
                console.log(props.user);
                // const res = await addIntegrationDriveService(index_info, props.user.id);
                //console.log("new index", res);
                await props.updateDriveUser(index_info, props.user.id)
            } else {
                let index_info_update = {
                    "index": {
                        "collection_id": `${props.user.email}-${gdToken.email}-googledrive`,
                        "email": gdToken.email,
                        "token": gdToken.token,
                        "exp_date": gdToken.expiry
                    }
                }
                // const res = await updateIndexDriveService(index_info_update, props.user.id);
                // console.log('updated token', res);
                await props.updateIndexUser(index_info_update, props.user.id)
            }
            setLoading(false)
            /**NEW message, because the process runs in the background*/
            // toastr.success(`${response?.length || 0} Files have been successfully indexed`);
            toastr.success(`${response?.message}`);

            /** for the alert*/
            // setOpenAlert(true);
            // setFilesResult(response?.message);

            // update file list
            let folders = await getFoldersGoogleDriveService(gdToken.token, props.user.email)
            console.log('folders updated: ', folders)
            setGoogleDriveFolders(folders)
        } catch (e) {
            toastr.error(`Something went wrong, try again`);
        }
        setLoading(false);
    }

    const deleteGoogleDriveFolderFiles = async (folder_id) => {
        setLoading(true)
        try {
            //exist user and index
            // const response = await loadGoogleDriveFolderFilesService(gdToken.token, folder_id)
            const response = await deleteGoogleDriveFolderFilesService(gdToken.token, folder_id, props.user.email)
            console.log(response);
            //TODO: control user not authenticated
            setLoading(false)
            toastr.success(`${response?.status}`);

            /** for the alert*/
            // setOpenAlert(true);
            // setFilesResult(response?.status);

            // update file list
            let folders = await getFoldersGoogleDriveService(gdToken.token, props.user.email)
            console.log('folders updated: ', folders)
            setGoogleDriveFolders(folders)
        } catch (e) {
            toastr.error(`Something went wrong, try again`);
        }
        setLoading(false);
    }

    /******* ONEDRIVE ******/

    const loadFolderOneDrive = async (emailTeams="") => {
        // console.log("--- data input is: ", token, emailTeams)

        setLoading(true)
        setShowFolders(true);
        setIntegration('onedrive');

        /** get onedrive token*/
        if (!props.user.microsoftAccounts.length) {
            toastr.warning("No Integration found", "First you need to integrate a OneDrive account")
            return;
        }
        const tok = await getOnedriveTokenService(props.user.microsoftAccounts[0].email);

        if (!tok) {
            toastr.warning("Session Expired", "Go to integrations and integrate OneDrive again")
            return;
        }
        setOdToken(tok);

        try {
            const folders = await getFoldersOneDriveService(tok.token, props.user.email, tok.tenantId)
            setOneDriveFolders(folders)
        } catch (e) {
            console.log("get folders onedrive: ", e)
            toastr.warning("Ups!", "Something went wrong getting folders")
        } finally {
            setLoading(false)
        }
    }

    const loadOneDriveFolderFiles = async (folder) => {
        setLoading(true)
        try {
            /** get onedrive token*/
            if (!props.user.microsoftAccounts?.length || !odToken){
                toastr.warning("No Integration found", "First you need to integrate a OneDrive account")
                return;
            }
            const response = await loadOneDriveFolderFilesService(odToken.token, folder.name, folder.id, props.user.email, odToken.tenantId)
            console.log(response);
            /**NEW message, because the process runs in the background*/
            toastr.success(`${response?.message}`);

            setTimeout(() => '', 500);
            const folders = await getFoldersOneDriveService(odToken.token, props.user.email, odToken.tenantId)
            setOneDriveFolders(folders);

            /** for the alert*/
            // setOpenAlert(true);
            // setFilesResult(response?.message);

        } catch (e) {
            toastr.error(`Something went wrong, try again`);
        } finally {
            setLoading(false)
        }
    }

    const deleteOneDriveFolderFiles = async (folder, folder_id) => {
        setLoading(true)
        try {
            if (!odToken){
                toastr.warning("No Integration found", "First you need to integrate a OneDrive account")
                return;
            }

            const response = await deleteOneDriveFolderFilesService(odToken?.token, folder, folder_id, props.user.email, odToken?.tenantId)
            console.log(response);
            /**NEW message, because the process runs in the background*/
            toastr.success(`${response?.message}`);
            setLoading(false)

            const folders = await getFoldersOneDriveService(odToken?.token, props.user.email, odToken?.tenantId)
            setOneDriveFolders(folders)

            /** for the alert*/
            // setOpenAlert(true);
            // setFilesResult(response?.message);

        } catch (e) {
            toastr.error(`Something went wrong, try again`);
        }

        setLoading(false)
    }

    return (<div>
        <div className="">
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{position: 'absolute', top: 70, right: 16}}
                icon={<SpeedDialIcon openIcon={<Plagiarism/>} icon={<RuleFolder/>}/>}
                direction="down"
            >
                <SpeedDialAction
                    icon={<img src={googleLogo} alt="" style={{width: '25px'}}/>}
                    tooltipTitle={"GoogleDrive Files"}
                    onClick={() => handlerGoogleCallbackMessage()}
                />
                <SpeedDialAction
                    icon={<img src={onedriveLogo} alt="" style={{width: '32px'}}/>}
                    tooltipTitle={"OneDrive Files"}
                    onClick={() => loadFolderOneDrive()}
                />
            </SpeedDial>
        </div>
        <Drawer
            anchor="right"
            open={showFolders}
            sx={{maxWidth: '50vw'}}
            onClose={() => setShowFolders(false)}>
            {loading &&
                <div className="mx-3">
                    <Skeleton variant="text" sx={{fontSize: '1rem'}}/>

                    <Skeleton className="my-2" variant="rounded" width={210} height={60}/>
                    <Skeleton className="my-2" variant="rounded" width={210} height={60}/>
                    <Skeleton className="my-2" variant="rounded" width={210} height={60}/>
                    <Skeleton className="my-2" variant="rounded" width={210} height={60}/>
                    <Skeleton className="my-2" variant="rounded" width={210} height={60}/>
                    <Skeleton className="my-2" variant="rounded" width={210} height={60}/>

                </div>
            }

            {!loading && integration === 'onedrive' &&
            <OnedriveFolderIndex folders={oneDriveFolders} loadOnedriveFolder={loadOneDriveFolderFiles} deleteOneDriveFolderFiles={deleteOneDriveFolderFiles}/>}

            {!loading && integration === 'googledrive' && googleDriveFolders?.length > 0 &&
            <GoogleDriveFolderIndex folders={googleDriveFolders} loadGoogleDriveFolder={loadGoogleDriveFolderFiles} deleteGoogleDriveFolderFiles={deleteGoogleDriveFolderFiles}/>}
        </Drawer>
    </div>)
}

const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
        status: state.users.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateDriveUser: (obj, id) => dispatch(updateDriveUserThunk(obj, id)),
        updateIndexUser: (obj, id) => dispatch(updateIndexUserThunk(obj, id)),
        deleteDriveUser: (token, masterEmail, tenantId, uniqueId) => dispatch(deleteDriveUserThunk(token, masterEmail, tenantId, uniqueId)),
        deleteGoogleDriveUser: (token, masterEmail) => dispatch(deleteGoogleDriveUserThunk(token, masterEmail))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriveListIndex);
