import {connect} from "react-redux";
import {Badge, Chip, CircularProgress, Tooltip} from "@mui/material";
import {Cloud} from "@mui/icons-material";
import logo from "../../../assets/GoogleDriveLogo.png";
const CustomChip = (props) => {
    return (<Chip variant="filled"
                  disabled={props.status === 'loading'}
                  icon={props.status === 'loading' ? <CircularProgress size={20}/> : <img style={{width: '35px', height: '35px'}} src={logo} />}
                  label="GOOGLE DRIVE"
                  {...props}/>)
}

const GoogledriveButton = (props) => {
    const {openGoogleOauthPopUp, deleteGoogleDriveAccount} = props;

    const existDriveAccount = () => {
        return props.user.googleAccounts !== null && props.user.googleAccounts?.length > 0
    }

    return (
        <Tooltip
            title={existDriveAccount() ? props.user.googleAccounts[0]["email"] : ''} className="w-100">
            <Badge color="success" variant="dot" invisible={!existDriveAccount()}>
                {existDriveAccount() ?
                    <CustomChip label={<small className="text-black-50">Add more folders</small>}
                                className="py-4 w-100 justify-content-between"
                                onClick={() => openGoogleOauthPopUp(false)}
                                onDelete={() => deleteGoogleDriveAccount()}
                                status={props.status}
                /> : <CustomChip className="py-4 w-100"
                                 onClick={() => openGoogleOauthPopUp(false)}
                                 status={props.status}
                    />}
            </Badge>
        </Tooltip>)
}


const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        status: state.users.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogledriveButton);
