import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import {Menu} from "@mui/material";
import {Cable, Circle, KeyboardArrowRight} from "@mui/icons-material";
import {useEffect, useState} from "react";
import googleDriveLogo from "../../../../../assets/GoogleDriveLogo.png";
import oneDriveLogo from "../../../../../assets/OneDrive-Logo.png";
import {setContainer, setIndex, setIndexObj} from "../../../../../redux/agent-hr/agent-hr.actions";
import {connect} from "react-redux";
import GenerateSlides from "../slides/GenerateSlides";
import {getAllFilesDriveService} from "../../../../../services/drive-hr.service";
import {getUniqueFileTypes} from "../../helpers/files";
import FilesMenu from "./files-menu";

function DriveChooseIndex(props) {
    const {anchorEl, handleOpen} = props;

    /*drive submenu*/
    let submenuTimeout;
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
    const [submenuOpen, setSubmenuOpen] = useState(false);

    const [allFilesDrive, setAllFilesDrive] = useState([])
    const [mimeTypes, setMimeTypes] = useState([])
    const [filesByType, setFilesByType] = useState([])


    useEffect(() => {
        const f = async () => {
            const data = await getAllFilesDriveService(props.user.email);
            if (data.length > 0) {
                setAllFilesDrive(data);
                setMimeTypes(getUniqueFileTypes(data));
            } else {
                const testValues = [
                    [
                        "191lmT9XND7ejdAg43CWU1Aq7fuRg1vWG74ATkCrNlEI",
                        "transcript from youtube",
                        "document"
                    ],
                    [
                        "01TQBVCIQPJ3PXXN6DPNHLWJOKW45XUDPO",
                        "Cinderella.pdf",
                        "document"
                    ],
                    [
                        "1FNY0jOW9iFop5apOMRT3eWa7-Hh2Blr_",
                        "Copia de 1 The Final Problem Autor Arthur Conan Doyle.pdf",
                        "document"
                    ],
                    [
                        "1pbrnPXbc4FBDIqXTZgDxe5t9YZQb2rU7gfoavpnLcTs",
                        "document 3",
                        "sheet"
                    ],
                    [
                        "1YLyk1oXnsghU_tKXlUrMtoU5fd4RJT0o",
                        "medical case.pdf",
                        "document"
                    ],
                    [
                        "1BPwxH6wV1qUbQcA2O6YGG5LRYI9y_zpI",
                        "Final Job Resource Document 2022.pdf",
                        "document"
                    ],
                    [
                        "01TQBVCIUPJQD2KNDEX5EJDT5BGCFMFCAT",
                        "Cinderella 2.pdf",
                        "document"
                    ],
                    [
                        "1-DTnyNo1AEqn4Hl2V7kNgr9-x-2553zd",
                        "Cinderella.pdf",
                        "document"
                    ],
                    [
                        "1SH9OaaCAyvnYowRN1LHOTtL5hiacZSxNQb-RELPqVb8",
                        "test",
                        "image"
                    ]
                ];

                setAllFilesDrive(testValues);
                setMimeTypes(getUniqueFileTypes(testValues));
            }
        }
        if (anchorEl) f();
    }, [anchorEl]);

    const filterDriveFiles = (mimeType) => {
        const tmp = allFilesDrive.filter(subArray => {
            const fileExtension = subArray[2];
            return fileExtension === mimeType;
        });

        setFilesByType(tmp);
    }

    const handleMenuClose = () => {
        handleOpen(null);
        handleSubmenuClose();
    };

    const handleSubmenuOpen = (event) => {
        setSubmenuAnchorEl(event.currentTarget);
        setSubmenuOpen(true);
    };

    const handleSubmenuClose = () => {
        setSubmenuAnchorEl(null);
        setSubmenuOpen(false);
        handleOpen(null);
    };

    const handleDrive = (drive) => {
        let item = null;
        // console.log("OBJJJJ", props.indexObj)
        if (drive === 'googledrive') {
            if ((props.user.googleAccounts && props.user.googleAccounts.length > 0) && (!props.indexObj || props.indexObj?.drive !== 'googledrive')) {
                const googleEmail = props.user.googleAccounts[0].email;
                item = props.user.indexList.find((it) => {
                    return it?.drive === 'googledrive' && it?.collection_id.includes(`${googleEmail}-googledrive`);
                })
            }
        }

        if (drive === 'onedrive') {
            if ((props.user.microsoftAccounts && props.user.microsoftAccounts.length > 0) && (!props.indexObj || props.indexObj.drive !== 'onedrive')) {
                const oneEmail = props.user.microsoftAccounts[0].email;
                item = props.user.indexList.find((it) => {
                    return it?.drive === 'onedrive' && it?.collection_id.includes(`${oneEmail}-onedrive`);
                })
            }
        }
        // console.log("ITTTT", item)
        if (item) {
            props.setIndexName(item.value);
            props.setContainer(item.container);
            props.setIndex(item);
        } else {
            props.setIndexName('empty-data')
            props.setIndex(null)
            props.setContainer('content-hr')
        }
        setTimeout(() => {
            handleSubmenuClose();
        }, 500)
    };

    const enabledGoogleDrive = () => {
        const haveIntegration = props.user.googleAccounts && props.user.googleAccounts.length > 0
        let haveIndex = null;
        if (haveIntegration) {
            const googleEmail = props.user.googleAccounts[0].email;
            haveIndex = props.user.indexList.find((it) => {
                return it?.drive === 'googledrive' && it?.collection_id.includes(`${googleEmail}-googledrive`);
            })
        }

        return haveIntegration && haveIndex;
    }

    const enabledOneDrive = () => {
        const haveIntegration = props.user.microsoftAccounts && props.user.microsoftAccounts.length > 0
        let haveIndex = null;
        if (haveIntegration) {
            const oneEmail = props.user.microsoftAccounts[0].email;
            haveIndex = props.user.indexList.find((it) => {
                return it?.drive === 'onedrive' && it?.collection_id.includes(`${oneEmail}-onedrive`);
            })
        }

        return haveIntegration && haveIndex;
    }

    return (
        <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{mt: {sm: '-80px', xs: '-90px'}}}
            open={Boolean(anchorEl)}
            onClose={() => handleMenuClose()}
        >
            <MenuList>
                <FilesMenu anchorEl={anchorEl} handleOpen={handleOpen} filterDriveFiles={filterDriveFiles} allFilesDrive={allFilesDrive} mimeTypes={mimeTypes} filesByType={filesByType} setDriveFileId={props.setDriveFileId} driveFileId={props.driveFileId}/>
                {/*<MenuItem onClick={!submenuOpen ? handleSubmenuOpen : handleSubmenuClose} >
                    <ListItemIcon>
                        <Cable fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Connect to</ListItemText>
                    <ListItemIcon className="ps-3">
                        <KeyboardArrowRight fontSize="small" />
                    </ListItemIcon>
                </MenuItem>*/}

                {/* Submenu DRIVE */}
                {/*<Menu
                    anchorEl={submenuAnchorEl}
                    open={submenuOpen}
                    onClose={handleSubmenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{mt: '-16px'}}
                >
                    <MenuItem onClick={() => handleDrive('googledrive')}
                              disabled={!enabledGoogleDrive()}>
                        <ListItemIcon>
                            <img style={{width: '25px', height: '25px'}} src={googleDriveLogo} />
                        </ListItemIcon>
                        <ListItemText>Google Drive</ListItemText>
                        {props.indexObj && props.indexObj.drive === 'googledrive' && <ListItemIcon className="ps-3 text-success">
                            <Circle fontSize="small" />
                        </ListItemIcon>}
                    </MenuItem>
                    <MenuItem onClick={() => handleDrive('onedrive')}
                              disabled={!enabledOneDrive()}>
                        <ListItemIcon>
                            <img style={{width: '34px'}} src={oneDriveLogo} />
                        </ListItemIcon>
                        <ListItemText>OneDrive</ListItemText>
                        {props.indexObj && props.indexObj.drive === 'onedrive' && <ListItemIcon className="ps-3 text-success">
                            <Circle fontSize="small" />
                        </ListItemIcon>}
                    </MenuItem>
                </Menu>*/}

                {props.answers.length > 0 && <Divider />}
                {props.answers.length > 0 && <GenerateSlides inputData={props.answers} inMenu={true}/>}
            </MenuList>
        </Menu>
    );
}

const mapStateToProps = (state) => {
    console.log('state app:', state)
    return {
        user: state.users.user,
        indexName: state.agentHrs.index,
        indexObj: state.agentHrs.indexObj
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIndexName: (index) => dispatch(setIndex(index)),
        setContainer: (container) => dispatch(setContainer(container)),
        setIndex: (obj) => dispatch(setIndexObj(obj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriveChooseIndex);

