import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, CircularProgress, Box } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import "./thinking-indicator.css";

const ThinkingIndicator = () => {
    const [seconds, setSeconds] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prev) => prev + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="thinking-container">
            <Card className="thinking-card">
                <CardContent className="d-flex align-items-center">
                    <CircularProgress size={24} className="me-2 text-primary" />
                    <Box>
                        <Typography variant="body1" className="thinking-text">Thinking for {seconds} seconds</Typography>
                        <Typography variant="body2" className="thinking-subtext">
                            <div className="h-24">
                                <div className="cubespinner">
                                    <div className="face1">
                                        Hold on while we are generating insights for you...
                                    </div>
                                    <div className="face2">
                                        Copilot is working on it...
                                    </div>
                                    <div className="face3">
                                        Processing the data...
                                    </div>
                                </div>
                            </div>
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default ThinkingIndicator;
