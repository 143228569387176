import axios from "axios";

export const storeTokenOneDriveService = async (token, tenantId) => {
    const {data} = await axios.post(`${process.env.REACT_APP_GET_ONEDRIVE}/store-refreshtoken`, {tenantId}, {
        headers: {
            'authorization': `Bearer ${token}`,
            // 'access-key': accessToken
        }
    });
    return data;
}

export const getFoldersOneDriveService = async (token, masterEmail, tenantId) => {
    const {data} = await axios.post(`${process.env.REACT_APP_GET_ONEDRIVE}/get-onedrive-folders`, {
        email: masterEmail,
        tenantId
    }, {
        headers: {
            'authorization': `Bearer ${token}`,
            // 'access-key': accessToken
        }
    });

    return data;
}

export const getOnedriveTokenService = async (email) => {
    const {data} = await axios.post(`${process.env.REACT_APP_GET_GOOGLEDRIVE}/get-onedrive-tk`,{
        userEmail: email
    });

    return data;
}

export const loadOneDriveFolderFilesService = async (token, folderName, folderId, masterEmail, tenantId) => {
    const {data} = await axios.post(`${process.env.REACT_APP_GET_ONEDRIVE}/get-onedrive-files`,{
        folder: folderName,
        folder_id: folderId,
        email: masterEmail,
        tenantId
    }, {
        headers: {
            'authorization': `Bearer ${token}`
        }
    });

    return data;
}

export const deleteOneDriveFolderFilesService = async (token, folderName, folderId, masterEmail, tenantId) => {
    const {data} = await axios.post(`${process.env.REACT_APP_GET_ONEDRIVE}/delete-folder-onedrive`,{
        folder: folderName,
        folder_id: folderId,
        email: masterEmail,
        tenantId
    }, {
        headers: {
            'authorization': `Bearer ${token}`
        }
    });

    return data;
}

export const deleteOneDriveAccountService = async (token, masterEmail, tenantId, uniqueId) => {
    const {data} = await axios.post(`${process.env.REACT_APP_GET_ONEDRIVE}/delete-onedrive-account`,{
        email: masterEmail,
        tenantId,
        userId: uniqueId
    }, {
        headers: {
            'authorization': `Bearer ${token}`
        }
    });

    return data;
}
export const deleteGoogleDriveAccountService = async (token, masterEmail) => {
    const {data} = await axios.post(`${process.env.REACT_APP_GET_GOOGLEDRIVE}/google/delete_account`,{
        email: masterEmail,
    }, {
        headers: {
            'authorization': `Bearer ${token}`
        }
    });

    return data;
}

/// FOR GOOGLE DRIVE
export const refreshTokenGoogleDriveService = async (last_token, email) => {
    // TODO manage the pop up responses
    const {data} = await axios.post(`${process.env.REACT_APP_LOGIN_GOOGLEDRIVE}/api/get-token?location=${window.location.origin}`, {
        email
    }, {
        headers: {
            'Authorization': `Bearer ${last_token}`
        }
    });

    return data;
}

export const getFoldersGoogleDriveService = async (token, masterEmail) => {
    // TODO add control when the token expires
    const {data} = await axios.post(`${process.env.REACT_APP_GET_GOOGLEDRIVE}/google/get_folders`, {email: masterEmail}, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    return data;
}

export const loadGoogleDriveFolderFilesService = async (token, folderId, mimeType, masterEmail) => {
    const {data} = await axios.post(`${process.env.REACT_APP_GET_GOOGLEDRIVE}/google/load_files`, 
        {
            folder_id: folderId,
            mime_type: mimeType,
            email: masterEmail
        }, 
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );

    return data;
}

export const deleteGoogleDriveFolderFilesService = async (token, folderId, masterEmail) => {
    const {data} = await axios.post(`${process.env.REACT_APP_GET_GOOGLEDRIVE}/google/delete_folder`,
        {
            folder_id: folderId,
            email: masterEmail
        },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );

    return data;
}

export const getAllFilesDriveService = async (masterEmail) => {
    const {data} = await axios.post(`${process.env.REACT_APP_GET_GOOGLEDRIVE}/unified/get_files`, {master_email: masterEmail});
    // const {data} = await axios.post(`https://d9e5-200-110-49-80.ngrok-free.app/unified/get_files`, {master_email: masterEmail});
    return data;
}
////////////////
