import {
    Grid, IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader, Tooltip,
    Typography
} from "@mui/material";
import * as PropTypes from "prop-types";
import {CheckCircle, Folder, RemoveCircle} from "@mui/icons-material";
import {useState} from "react";
import IconFile from "../icon-file";

function Demo(props) {
    return null;
}

Demo.propTypes = {children: PropTypes.node};
const GoogleDriveFolderIndex = (props) => {
    const {folders, loadGoogleDriveFolder, deleteGoogleDriveFolderFiles} = props;
    const [dense, setDense] = useState(true);
    const [secondary, setSecondary] = useState(false);
    return (
                    <List
                        sx={{
                            width: '100%',
                            bgcolor: 'background.white',
                            overflow: 'auto',
                            maxHeight: '100vh',
                            maxWidth: {xs: '95vw', sm: '50vw'},
                            '& ul': { padding: 0 }
                        }}
                        subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            GoogleDrive Folders
                        </ListSubheader>
                    }>
                        {folders.length > 0 ? folders.map((folder, index) =>
                            <ListItem
                                key={index}
                                secondaryAction={
                                    folder.stored ? <Tooltip title="Remove">
                                        <IconButton color="error" edge="end" aria-label="remove" onClick={() => deleteGoogleDriveFolderFiles(folder.id)} >
                                        <RemoveCircle />
                                    </IconButton>
                                    </Tooltip>: null
                                }
                                disablePadding>
                            <ListItemButton
                                            onClick={() => loadGoogleDriveFolder(folder.id, folder.mimeType)}
                                            disabled={folder.stored}>
                                <ListItemIcon>
                                    {folder.mimeType == "application/vnd.google-apps.folder" ? <Folder/> : <IconFile item={folder}/>}
                                </ListItemIcon>                                
                                <ListItemText
                                    primary={folder.name}
                                    secondary={`${folder.trashed == true?'TRASHED':''}`}
                                />
                            </ListItemButton>
                            </ListItem>,
                        ) : <ListItem disablePadding>
                        <ListItemText
                            primary={<span className="ms-3 text-secondary">Root is empty</span>}
                        />
                    </ListItem>}
                    </List>
    )
}

export default GoogleDriveFolderIndex;
