import { useEffect, useRef, useState } from 'react'
import Typed from 'react-typed'
import {
    getFollowup,
    getInsight, setChatHrOnedriveService,
    setChatHrGoogledriveService,
    setChatHrService,
    storeConversationMessage, streamResponse, getActionPlanService, updateRateService, setChatHrSingleService
} from '../../../services/agent-hr.service'
import { refreshTokenGoogleDriveService } from '../../../services/drive-hr.service'
import { updateIndexDriveService } from "../../../services/user.service";
import { toastr } from "react-redux-toastr";
import {Box, Stack, Tooltip, Typography, useMediaQuery} from "@mui/material";
import RotatingSpinner from "../../layouts/RotatingSpinner";
import { AnalysisPanelTabs, Approaches } from '../models'
import styles from './agent-hr-chat-index.module.css'
import { ClearChatButton } from './components/clear-chat-button/clear-chat-button'
import ExampleList from './components/example/example-list'
import UserChatMessage from './components/user-chat-message/user-chat-message'
import Answer from './components/answer/answer'
import AnswerLoading from './components/answer/answer-loading'
import { AnswerError } from './components/answer/answer-error'
import { QuestionInput } from './components/question-input/question-input'
import { AnalysisPanel } from './components/analysis-panel/analysis-panel'
import { ChatDrawerLeft } from './components/history-panel/ChatDrawerLeft'
import { ConfirmDelete } from "./components/history-panel/ConfirmDelete";
import { ModifyConversationTitle } from "./components/history-panel/ModifyConversationTitle";
import {
    Checkbox,
    DefaultButton,
    Panel,
    SpinButton,
    TextField,
} from '@fluentui/react'
import {
    addUserThunk,
    editUserThunk,
    getUserWithEmailThunk,
} from '../../../redux/users/users.thunk'
import { updateCountry } from '../../../redux/users/users.actions'
import { connect } from 'react-redux'
import { SendEmailButton } from './components/send-email-button/send-email-button'
import { Button, IconButton } from '@mui/material'
import {ArrowBack, ChevronLeft, ChevronRight, Menu, MenuOpen} from '@mui/icons-material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import SelectIndex from "./components/select-index/selet-index";
import { setContainer, setCopilotTheme, setIndex, setIndexObj } from "../../../redux/agent-hr/agent-hr.actions";
import PbiQaIndex from "./components/pbi-qa/pbi-qa-index";
import ActionPlan from "./components/answer/action-plan";
import {
    createHistoryFromQuestionsAndAnswers,
    createQuestionAndAnswersFromMessages,
  } from "../../../helpers/messages";
import GenerateSlides from "./components/slides/GenerateSlides";
import {ChartGeneratorData} from "./components/graphics/ChartGenerator";
import DriveChoose from "./components/drive-choose/drive-choose";
import {useAwsCopilotJustPaidGuard} from "../../../guards/aws-copilot-just-paid-guard";
import {useAwsGuard} from "../../../guards/aws-guard";
import DriveUsed from "./components/drive-choose/drive-used";
import DriveListIndex from "./components/drive-files-list/drive-list-index";
import ThinkingIndicator from "./components/thinking/thinking-indicator";

const AgentHrChatIndex = (props) => {
    const { conversationId } = useParams();
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false)
    const [promptTemplate, setPromptTemplate] = useState('')
    const [retrieveCount, setRetrieveCount] = useState(10)
    const [useSemanticRanker, setUseSemanticRanker] = useState(false)
    const [useSemanticCaptions, setUseSemanticCaptions] = useState(false)
    const [excludeCategory, setExcludeCategory] = useState('')
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] =
        useState(true)

    const lastQuestionRef = useRef('')
    const chatMessageStreamEnd = useRef(null)

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingInsight, setIsLoadingInsight] = useState(false)
    const [isLoadingFollowup, setIsLoadingFollowup] = useState(false)
    const [error, setError] = useState(null)

    const [activeCitation, setActiveCitation] = useState()
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] =
        useState(undefined)

    const [selectedAnswer, setSelectedAnswer] = useState(0)
    const [answers, setAnswers] = useState([])
    const [route, setRoute] = useState("DEFAULT")

    const [language, setLanguage] = useState('English')
    const [hrId, setHrId] = useState(null)
    const [init, setInit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileName, setFileName] = useState(null)

    // const [indexName, setIndexName] = useState(props.user?.whitelisted ? 'hr-index' : 'empty-data')

    const [startComponent, setStartComponent] = useState(null)

    const [typing, setTyping] = useState(false)
    const location = useLocation()
    const contentRef = useRef(null);

    // for show pbi Q&A
    const [showQa, setShowQa] = useState(false)
    const [currentMessageQa, setCurrentMessageQa] = useState(null)

    // for google drive
    const [gdToken, setGdToken] = useState({}); // only for development

    /**for Action Plan*/
    const [openActionPlan, setOpenActionPlan] = useState(false);
    const [loadingActionPlan, setLoadingActionPlan] = useState(false);
    const [actionPlan, setActionPlan] = useState(null);
    
    //for history
    const didMount = useRef(false);

    /** for drive select file*/
    const [driveFileId, setDriveFileId] = useState('');

    const [saveUserQuestion, setSaveUserQuestion] = useState(true);
    useEffect(() => {
        console.log("PASS for here", conversationId)
        // resetMessagesPanel();
        if(!conversationId) {
            setHrId(null);
            return;
        }
        onModifyConversationTitle(true)
        setLoadingConversationMsg('Loading conversation...')
        didMount.current = false;
        console.log("conversationId:", conversationId);
        (async () => {

         const {evaluations, conversation} = await storeConversationMessage({
                  hrId: conversationId,
                  type: "get-all-messages",
                });

         /**update the index Name saved*/
         if (conversation?.indexName) {
             let inObj = props.user.indexList.find((it) => it.value === conversation.indexName);
             props.setIndexName(conversation.indexName);
             props.setIndexObj(inObj)

         }

         const storedMessages = evaluations;
         console.log("stored messages:", storedMessages);
         const { questionsAndAnswers, lastSourceName, lastContainerName } =
         createQuestionAndAnswersFromMessages(storedMessages);
         console.log("QUESTIONS AND ANSWERS:", questionsAndAnswers);
        
         setAnswers(questionsAndAnswers)
         if (questionsAndAnswers.length > 0) {
             lastQuestionRef.current = questionsAndAnswers[questionsAndAnswers.length - 1]
         }
         setHrId(conversationId)
         setLoading(false)
        setInit(false)
        onModifyConversationTitle(false)
        setLoadingConversationMsg('')
            console.log("LOCATION: ", location.state, answers)
        if (location.state?.userQuestion && hrId && answers.length == 0) {
            // setSaveUserQuestion(false);
            const userQuestion = location.state.userQuestion;
            location.state.userQuestion = null;
            console.log("FIRST MSG", hrId, userQuestion)
            console.log("LOCATION AFTER: ", location.state)
            await makeApiRequest(userQuestion, false)
            // navigate(location.pathname, { replace: true });

        }
        
        //   setIsLoadingMessages(true);
        //   // get all messages from conversation
        //   const storedMessages = (
        //     await storeConversationMessage({
        //       hrId: conversationId,
        //       type: "get-all-messages",
        //     })
        //   ).evaluations;
        //   console.log("stored messages:", storedMessages);
    
        //   const { questionsAndAnswers, lastSourceName, lastContainerName } =
        //     createQuestionAndAnswersFromMessages(storedMessages);
        //   console.log("QUESTIONS AND ANSWERS:", questionsAndAnswers);
    
        //   setQuestionsAndAnswers(questionsAndAnswers);
        //   setIndexName(
        //     lastSourceName !== "" ? lastSourceName : user.indexList[0].value
        //   );
        //   setContainerName(
        //     lastContainerName !== ""
        //       ? lastContainerName
        //       : user.indexList[0].container
        //   );
        //   setIsLoadingMessages(false);
        })();
      }, [hrId, conversationId]);

    /** For history */
    const navigate = useNavigate();
    const [isDrawerLeftOpen, setIsDrawerLeftOpen] = useState(true);
    const [conversations, setConversations] = useState([]);
    const [isLoadingConversations, setIsLoadingConversations] = useState(false);
    const conversationToDeleteId = useRef(null);
    const conversationToModifyId = useRef(null);
    const [isModifyConversationTitleOpen, setIsModifyConversationTitleOpen] = useState(false);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
    const [isLoadingNewConversation, onModifyConversationTitle] = useState(false);
    const [loadingConversationMsg, setLoadingConversationMsg] = useState('');
    const [isLoadingDeleteConversation, setIsLoadingDeleteConversation] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoadingConversations(true);
            try {
                // get all conversations from user
                const conversations = (
                    await storeConversationMessage({
                    analyticsUserId: props.user.id,
                    type: "get-all-not-deleted",
                    })
                ).evaluations;
                setConversations(conversations.reverse().slice(0, 100));
                setIsLoadingConversations(false);
            } catch (err) {
                console.log(err);
                setError(err);
                setIsLoadingConversations(false);
            }
        })();
        }, []);

    /**aws permissions*/
    const [hasAws, setHasAws] = useState(useAwsGuard())


    useEffect(() => {
        // searching for Watson index
        /*let index_obj_ccs = null
        for(let idx in props.user.indexList)
            if(props.user.indexList[idx].value == "ccs-googledrive"){
                index_obj_ccs = props.user.indexList[idx]
                break
            }*/
        //
        if(!conversationId) {
            setStartComponent(location.pathname)
            goBack(false)

            /*props.setIndexName(props.user?.whitelisted ? props.user.indexList?.length > 0 ? props.user.indexList[0].value : 'empty-data' : 'empty-data')
            props.setIndexObj(props.user?.whitelisted ? props.user.indexList?.length > 0 ? props.user.indexList[0] : null : null)
            props.setContainer('content-hr')*/
            if (hasAws && props.user.indexList?.length) {
                const obj = props.user.indexList.find((it) => it?.typeUser === 'aws-copilot')
                if (obj) {
                    props.setIndexName(obj.value)
                    props.setIndexObj(obj)
                    props.setContainer('content-hr')
                } else {
                    props.setIndexName('empty-data')
                    props.setIndexObj(null)
                    props.setContainer('content-hr')
                }
            } else {
                props.setIndexName('empty-data')
                props.setIndexObj(null)
                props.setContainer('content-hr')
            }
        }
    }, [location]) // TODO check if something brockes

    useEffect(() => {
        window.addEventListener('message', handlerGoogleCallbackMessage); // add event listener
        return () => {
           window.removeEventListener('message', handlerGoogleCallbackMessage); // remove event listener
        };
    }, [])
    // console.log('START', startComponent, location);

    const storeMessage = async (msg) => {
        const data = await storeConversationMessage({
            message: msg,
            hrId,
            type: 'store-messages',
        })
    }

    const readInsightsStream = async (answer, question, result, followup, indexName) => {

        const response = await getInsight(question, answer, indexName);
        setIsLoadingInsight(false);
        // console.log("IS STREAM", response)
        const reader = response.body?.getReader();
        const decoder = new TextDecoder();

        let completeResponse = "";
        let obj = ""
        const haveDataStr = '[moodbit_data_insights_in_comming]';
        let haveDataPoints = false;
        let haveExtraData = false;
        let searchSuggestions = { isLoading: true, data:[] };

        while (true) {
            // setTyping(true);
            /*console.log("SCROLLLL", contentRef.current.scrollTop, contentRef.current.scrollHeight);
            if (contentRef.current.scrollTop === contentRef.current.scrollHeight) {

            }*/
            // contentRef.current.scrollIntoView({behavior: 'smooth', block: "end"})
            // contentRef.current.scrollTop = contentRef.current.scrollHeight;
            const { value, done: doneReading } = await reader.read();
            if (doneReading) break;
            let chunkValue = decoder.decode(value);
            if (haveDataPoints) {
                obj += chunkValue;
            } else {
                if (chunkValue.includes(haveDataStr)) {
                    haveDataPoints = true;
                    const firstPos = chunkValue.indexOf(haveDataStr);
                    completeResponse += chunkValue.substring(0, firstPos);
                    obj += chunkValue.substring(firstPos + haveDataStr.length);
                } else {
                    // completeResponse += chunkValue;
                    if(chunkValue.includes("[moodbit_data_status_in_comming_start]")) {
                        haveExtraData=true;
                        console.log("CHUNK VALUE1:", chunkValue)
                        console.log("DataPoints1",haveDataPoints);
                    } else
                        if(chunkValue.includes("[moodbit_data_status_in_comming_end]")) {
                            haveExtraData=false;
                            console.log("CHUNK VALUE2:", chunkValue)
                        } else
                            if(haveExtraData) {
                                // setSomething(value => [...value, JSON.parse(chunkValue)]);
                                searchSuggestions.data.push(JSON.parse(chunkValue));
                                console.log("CHUNK VALUE:", chunkValue);
                            } else {
                                completeResponse += chunkValue;
                            }
                }
            }
            setAnswers([...answers, [question, { ...result, containerName: props.containerName, insight: completeResponse, answer: result.answer, followup: followup, finishInsight: false, searchSuggestions }]])
        }

        console.log("OBJJJ INSIGHTS", obj)

        if (obj !== "") {
            obj = JSON.parse(obj);
            setAnswers([...answers, [question, { ...result, containerName: props.containerName, insight: completeResponse, insightObject: obj.statistics, answer: result.answer, followup: followup, finishInsight: true, searchSuggestions }]])
        } else {
            setAnswers([...answers, [question, { ...result, containerName: props.containerName, insight: completeResponse, answer: result.answer, followup: followup, finishInsight: true, searchSuggestions }]])
        }
        return {...obj.statistics, content: completeResponse}
    }

    const newFollowup = async (question) => {
        console.log("FOOOLLO")
        setAnswers(answers.map((item, index) => {
            if (index === answers.length - 1) {
                item[1].followup = null;
            }
            return item;
        }))
        const history = answers.map((a) => ({ user: a[0], bot: a[1].answer, sql_bot: a[1].sqlQuery }))

        setIsLoadingFollowup(true);
        const { followup } = await getFollowup([...history, { user: question, bot: undefined }], props.indexName);
        setIsLoadingFollowup(false);

        console.log("FOLLOWUP", followup);

        setAnswers(answers.map((item, index) => {
            if (index === answers.length - 1) {
                item[1].followup = followup;
            }
            return item;
        }))
    }

    const openGoogleOauthPopUp = (force, actionType = "chat") => {
        // open the consent screen in a pop up
        if (force)
            window.open(`${process.env.REACT_APP_LOGIN_GOOGLEDRIVE}/api/get-token?force=true&location=${window.location.origin}`, '_blank', 'width=500,height=500');
        else
            window.open(`${process.env.REACT_APP_LOGIN_GOOGLEDRIVE}/api/get-token?location=${window.location.origin}`, '_blank', 'width=500,height=500');
    }

    let handlerGoogleCallbackMessage = async (event) => {
        try {
            // TODO control the case when the endpoint dont return the token
            if (event.origin != `${process.env.REACT_APP_LOGIN_GOOGLEDRIVE}`) {
                return; // ignore from another origin
            }
            let data = event.data;
            console.log('get data from tunnel', data);
            let index_info_update = {
                "index": {
                    "collection_id": `${props.user.email}-${data.email}-googledrive`,
                    "email": data.email,
                    "token": data.token,
                    "exp_date": data.expiry
                }
            }
            const res = await updateIndexDriveService(index_info_update, props.user.id); // TODO reload the props
            props.getUser(props.user.email);
            console.log('new token added', res);
            setGdToken(data)
        } catch (e) {
            console.log(e);
            toastr.error(`Something went wrong, try again`);
        }
    }

    const makeApiRequest = async (question, createConversation = true, attempt_number = 1) => {
        console.log("my question", question)
        lastQuestionRef.current = question
        error && setError(undefined)
        setIsLoading(true)
        setActiveCitation(undefined)
        setActiveAnalysisPanelTab(undefined)

        // hide qa, for every simple chat interaction
        setShowQa(false);

        try {
            const history = answers.map((a) => ({ user: a[0], bot: a[1].answer, sql_bot: a[1].sqlQuery }))
            const request = {
                history: [...history, { user: question, bot: undefined, route: route }],
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    prompt_template:
                        promptTemplate.length === 0 ? undefined : promptTemplate,
                    exclude_category:
                        excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semantic_ranker: useSemanticRanker,
                    semantic_captions: useSemanticCaptions,
                    suggest_followup_questions: useSuggestFollowupQuestions,
                },
                analytics_user_id: props.user.whitelisted
                    ? props.user.id
                    : 'empty-data',
                //analytics_user_id: 'hr-index-json-2',
                index_name: props.indexName,
                email: props.user.email,
                file_id: driveFileId
            }

            let response;            
            !(createConversation == true && history.length === 0) && setTimeout(async ()=>{
                if(response == undefined){
                    if(attempt_number < 3){
                        await makeApiRequest(question, false, attempt_number+1)
                    }
                    else{
                        setError("TIMEOUT: Please retry again")
                        setIsLoading(false)
                    }
                }
            }, 60000);
            let startTime = new Date().getTime();
            let drive = props.indexObj?.drive;
            console.log("INDEX OBJ ", props.indexObj, drive);
            if(history.length === 0 && !conversationId){
                console.log("THIS IS A NEW CONV")
                console.log(question, hrId)
                const newTitle = question.split(" ").slice(0, 5).join(" ")

                if (createConversation) {
                    await newConversation(newTitle, question);
                    return;
                }
            }
    
            if (props.indexObj && props.indexName !== 'empty-data') {
                response = await setChatHrService(request)
            } else {
                let extraFields = {};
                let googleDriveIndex = null;
                let oneDriveIndex = null;
                props.user.indexList.forEach((el) => { if(el.drive == 'googledrive') googleDriveIndex = el; });
                props.user.indexList.forEach((el) => { if(el.drive == 'onedrive') oneDriveIndex = el; });
                if (props.user.googleAccounts.length > 0 && googleDriveIndex != null) {
                    const current_date = new Date();
                    let gdTokenAux = gdToken;
                    if (!gdTokenAux.token) {
                        gdTokenAux = {
                            email: googleDriveIndex.collection_id,
                            token: googleDriveIndex.token,
                            expiry: googleDriveIndex.exp_date,
                        };
                        setGdToken(gdTokenAux);
                    }

                    let token = googleDriveIndex.token;
                    let exp_date = googleDriveIndex.exp_date;

                    if (gdTokenAux.token)
                        token = gdTokenAux.token;
                    if (gdTokenAux.expiry)
                        exp_date = gdTokenAux.expiry;
                    console.log("TOKEN INFO", gdTokenAux)
                    if ((new Date(exp_date * 1000)) <= current_date) {
                        const gEmail = props.user.googleAccounts[0]?.email;
                        let data = await refreshTokenGoogleDriveService(token, gEmail);
                        console.log("DATA REFRESHING", data);
                        if (data.token) {
                            let index_info_update = {
                                "index": {
                                    "collection_id": `${props.user.email}-${data.email}-googledrive`,
                                    "email": data.email,
                                    "token": data.token,
                                    "exp_date": data.expiry
                                }
                            }
                            const res = await updateIndexDriveService(index_info_update, props.user.id); // TODO reload the props
                            props.getUser(props.user.email)
                            setGdToken(data);
                            token = data.token;
                            console.log('new token added', res);
                        }
                        else {
                            console.log("DATA REFRESHING ERROR");
                            openGoogleOauthPopUp(true)
                            //alert('Reload the page please after login')
                            throw new Error(data.status);
                        }
                    }
                    extraFields['googledrive'] = { email: props.user.googleAccounts[0]['email'], token: token };
                }
                if (props.user.microsoftAccounts.length > 0 && oneDriveIndex != null) {
                    extraFields['onedrive'] = { email: props.user.microsoftAccounts[0]['email'], collection_id: oneDriveIndex.collection_id };
                }
                response = await setChatHrSingleService({...request, ...extraFields})
            }
            let currentTime = new Date().getTime();
            let elapsedTime = currentTime - startTime;
            console.log('ANSWER RECEIVED AFTER: ', elapsedTime);

            if(elapsedTime >= 60000)
                return;
            /** check the getReader*/
            console.log('RESPONSE', response);

            /// check if is stream or normal response
            const headers = [...response.headers];
            let isStream = true;
            headers.forEach((header) => {
                if (header[0] === 'content-type' && header[1] === 'application/json') {
                    isStream = false;
                }
            })

            let result = {};
            if (!isStream) {
                console.log("IS NORMAL")
                const resJson = await response.json();
                if (resJson.error_chat_google) {
                    if (resJson.error_chat_google == 'popup')
                        openGoogleOauthPopUp(true)
                    throw new Error(resJson.error_chat_google);
                }
                result = { ...resJson, finish: true, finishInsight: true };
                console.log('RESPONSE JSON', result);
                setAnswers([...answers, [question, result]]);
                setIsLoading(false);
            } else {
                console.log("IS STREAM", response)
                const reader = response.body?.getReader();
                const decoder = new TextDecoder();

                let completeResponse = "";

                let obj = "";

                let haveDataPoints = false;
                let haveExtraData = false;

                let searchSuggestions = { isLoading: true, data:[] };

                while (true) {
                    setTyping(true);
                    /*console.log("SCROLLLL", contentRef.current.scrollTop, contentRef.current.scrollHeight);
                    if (contentRef.current.scrollTop === contentRef.current.scrollHeight) {

                    }*/
                    contentRef.current.scrollIntoView({ behavior: 'smooth', block: "end" })
                    // contentRef.current.scrollTop = contentRef.current.scrollHeight;
                    const { value, done: doneReading } = await reader.read();
                    if (doneReading) break;
                    let chunkValue = decoder.decode(value, {stream: true});

                    const haveDataStr = '[moodbit_data_points_in_comming]';
                    if (haveDataPoints)
                        obj += chunkValue;
                    else {
                        if (chunkValue.includes(haveDataStr)) {
                            haveDataPoints = true;
                            console.log("DataPoints1",haveDataPoints);
                            const firstPos = chunkValue.indexOf(haveDataStr);
                            completeResponse += chunkValue.substring(0, firstPos);
                            obj += chunkValue.substring(firstPos + haveDataStr.length);
                        }
                        else
                        if(chunkValue.includes("[moodbit_data_status_in_comming_start]")) {
                            haveExtraData=true;
                            console.log("CHUNK VALUE1:", chunkValue)
                            console.log("DataPoints1",haveDataPoints);
                        } else {
                            if(chunkValue.includes("[moodbit_data_status_in_comming_end]")) {
                                haveExtraData=false;
                                console.log("CHUNK VALUE2:", chunkValue)
                            } else {
                                if(haveExtraData) {
                                    // setSomething(value => [...value, JSON.parse(chunkValue)]);
                                    searchSuggestions.data.push(JSON.parse(chunkValue));
                                    console.log("CHUNK VALUE:", chunkValue);
                                } else {
                                    completeResponse += chunkValue;
                                }
                            }
                        }
                    }
                    // setResponseTest(completeResponse);
                    result = { ...{}, answer: completeResponse, finish: false, finishInsight: true, searchSuggestions }
                    setAnswers([...answers, [question, { ...result, containerName: props.containerName }]])
                    setIsLoading(false)
                }
                // setTyping(false);
                obj = JSON.parse(obj);
                searchSuggestions.isLoading = false;
                result = { ...obj, answer: completeResponse, finish: true, finishInsight: true, searchSuggestions}
                setRoute(result.route)
                console.log("RESULT END", result)
            }

            setIsLoading(false) // until here finish the process to get the response

            // save and generate graphs if the answers require
            const graphic = await ChartGeneratorData(result);
            result = { ...result, graphic: graphic  };
            // console.log("graphic: ",graphic);
            setAnswers([...answers, [question, { ...result, graphic: graphic }]]);

            /**  generate the followup questions */
            setIsLoadingFollowup(true);
            const { followup } = await getFollowup([...history, { user: question, bot: result.answer }], props.indexName);
            setIsLoadingFollowup(false);

            // begin get the insights
            setIsLoadingInsight(true);

            let insightsObj = await readInsightsStream(result.answer, question, result, followup);

            // console.log("FOLLOWUP", followup);

            /**  generate the Insight for the answer if have */
            // const insight = await getInsight(result.answer);
            // console.log("INSIGHT", insight);


            setTyping(false);

            await storeMessage({
                role: 'user',
                content: question,
            })
            await storeMessage({
                ...result,
                role: 'assistant',
                content: result.answer,
                insights: insightsObj
            })
            // updating the message id
            const storedMessages = (
                await storeConversationMessage({
                  hrId: conversationId,
                  type: "get-all-messages",
                })
              ).evaluations;
            setAnswers((ans) => {
                if(ans?.length > 0 && storedMessages?.length > 0){
                    if(ans[ans.length-1][1].id === undefined)
                        ans[ans.length-1][1].id = storedMessages[storedMessages.length-1].id; 
                }
                return ans;
            })
            //
        } catch (e) {
            console.log('ERROR', e)
            if (typeof e === 'string' || e instanceof String) {
                e = JSON.parse(e);
                console.log('ERROR 2', e)
            }
            // setError(e.response?.data?.error)
            toastr.error(`Something went wrong, please reload the page to try again`);
            setError(e)
            setIsLoading(false)
        } finally {
            // setIsLoading(false);
        }
    }

    const clearChat = () => {
        lastQuestionRef.current = ''
        error && setError(undefined)
        setActiveCitation(undefined)
        setActiveAnalysisPanelTab(undefined)
        setAnswers([])
    }

    useEffect(
        () => chatMessageStreamEnd.current?.scrollIntoView({ behavior: 'smooth' }),
        [isLoading]
    )

    useEffect(() => {
        if(error && !isLoading) {
            setAnswers(value => {
                const answers = [...value];
                answers.pop();
                return answers;
            });
        }
    }, [error])

    /**for update the theme*/
    useEffect(() => {
        if (props.user.copilotTheme) {
            props.setCopilotTheme(props.user.copilotTheme)
        }
    }, [])
    const onPromptTemplateChange = (e, newValue) => {
        setPromptTemplate(newValue || '')
    }

    const onRetrieveCountChange = (_ev, newValue) => {
        setRetrieveCount(parseInt(newValue || '3'))
    }

    const onUseSemanticRankerChange = (_ev, checked) => {
        setUseSemanticRanker(!!checked)
    }

    const onUseSemanticCaptionsChange = (_ev, checked) => {
        setUseSemanticCaptions(!!checked)
    }

    const onExcludeCategoryChanged = (_ev, newValue) => {
        setExcludeCategory(newValue || '')
    }

    const onUseSuggestFollowupQuestionsChange = (_ev, checked) => {
        setUseSuggestFollowupQuestions(!!checked)
    }

    const onExampleClicked = (example) => {
        makeApiRequest(example)
    }

    const onShowCitation = (citation, name, index) => {
        console.log("CITATION CLICK", {citation, name, index})
        if (
            activeCitation === citation &&
            activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab &&
            selectedAnswer === index
        ) {
            setActiveAnalysisPanelTab(undefined)
            setFileName(null)
        } else {
            setActiveCitation(citation)
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab)
            setFileName(name)
        }
        setSelectedAnswer(index)
    }

    const onToggleTab = (tab, index) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined)
        } else {
            setActiveAnalysisPanelTab(tab)
        }

        setSelectedAnswer(index)
    }

    const newConversation = async (title = null, userQuestion = null) => {
        setIsDrawerLeftOpen(false);
        setAnswers([])
        // onModifyConversationTitle(true)
        // setLoadingConversationMsg('Loading new conversation...')
        const data = await storeConversationMessage({
            languageInput: language,
            analyticsUserId: props.user.id,
            title,
            indexName: props.indexName,
            type: 'store',
        })
        console.log(data)
        setHrId(data.evaluation.id)
        setInit(false)
        setConversations((prev) => [data.evaluation, ...prev]);        
        navigate(`/chat-hr/${data.evaluation.id}`, {state: {userQuestion}});
        // onModifyConversationTitle(false)
        setLoadingConversationMsg('')
    }

    const goBack = (dropHr = true) => {
        if (dropHr) setHrId(null);
        lastQuestionRef.current = '';
        setAnswers([]);
        setIsLoading(false);
    }

    /**for action plan*/
    const toggleActionPlan = (value) => () => {
        // console.log("toggle ACTION", value)
        setOpenActionPlan(value);
    }

    const getActionPlan = async (answer) => {
        // toggleActionPlan(true);
        setOpenActionPlan(true);
        if (!loadingActionPlan) {
            setLoadingActionPlan(true);
            // const aPlan = "none";
            const aPlan = await getActionPlanService(answer.insight)
            // console.log(aPlan);
            setActionPlan(aPlan)
            setLoadingActionPlan(false);
        }
    }

    const updateRateAnswer = async (item, message_id, rate) => {
        let question_id = 0;
        setAnswers(answers.map((answer, index) => {
            if (index === item) {
                answer[1].rate = rate;
                console.log("rate", answer)
                question_id = answer[1].question_id;
            }
            return answer;
        }))
        // for hr messages db
        if(message_id != undefined && rate != undefined)
            await storeConversationMessage({
                messageId: message_id,
                rate: rate,
                type: 'update-message',
            });

        if (question_id != 0 && question_id != undefined)
            await updateRateService({ question_id, rate })
    }

    //---
    const handleRedirectToConversation = (id) => {
        if(hrId === id) return;
        setIsDrawerLeftOpen(false);
        //navigate(`/chat-ccs-main-hr/${id}`);
        navigate(`/chat-hr/${id}`);
        setHrId(id);
    };

    const handleOpenConfirmDelete = (id) => {
        conversationToDeleteId.current = id;
        setIsConfirmDeleteOpen(true);
    };

    const handleOpenModifyConversationTitle = (id) => {
        conversationToModifyId.current = id;
        setIsModifyConversationTitleOpen(true);
    };

    const handleCloseConfirmDelete = () => {
        setIsConfirmDeleteOpen(false);
    };

    const handleModifyConversationTitle = async (title) => {
        await storeConversationMessage({
          title: title,
          conversationId: conversationToModifyId.current,
          type: "update",
        });
        setConversations((prev) => {
          const index = prev.findIndex(
            (conversation) => conversation.id === conversationToModifyId.current
          );
          prev[index].title = title;
          return prev;
        });
        setIsModifyConversationTitleOpen(false);
    };

    const handleDeleteConversation = async () => {
        setIsConfirmDeleteOpen(false);
        setIsLoadingDeleteConversation(true);
        await storeConversationMessage({
          conversationId: conversationToDeleteId.current,
          type: "logic-delete",
        });
        setConversations((prev) => {
          prev.splice(
            prev.findIndex(
              (conversation) => conversation.id === conversationToDeleteId.current
            ),
            1
          );
          return prev;
        });
        setIsLoadingDeleteConversation(false);
        toastr.success("The conversation was successfully deleted");
        if(conversationId == conversationToDeleteId.current) // the deleted conversation is the current one
            navigate(`/chat-hr`, {state: {}});

    };

    const handleCloseModifyConversationTitle = () => {
        setIsModifyConversationTitleOpen(false);
    };
    //---

    // console.log("ANS", answers)

    /** for drawer converation list*/
    const [open, setOpen] = useState(true);
    const handleDrawer = (value) => {
        setOpen(value);
    };

    return (
        <div className={styles.container}>
            {/* if the user is logged and not show pbi qa */}
            <DriveListIndex />
                <Box className={styles.topContainer} sx={{position: "fixed", zIndex: 10, mt: 1, ml: 2}}>
                    {!open &&
                    <Tooltip title="Show Conversations">
                        <IconButton variant="contained" size="large" onClick={() => handleDrawer(true)} color="primary">
                            <MenuOpen />
                        </IconButton>
                    </Tooltip>}
                    {/* {hrId && !showQa && (<div className={styles.btnBack}>
                        <ClearChatButton onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                        <Button
                            variant="contained"
                            onClick={() => goBack()}
                        >
                            <ArrowBack fontSize="large" />
                        </Button>
                    </div>)} */}
                </Box>
            <div style={{position: 'fixed', zIndex: 99, right: 70, paddingTop: 5}}>
                {<div className="w-100 d-flex justify-content-end mt-2">
                    {/*answers.length>0&&<GenerateSlides inputData={answers} />*/}
                    {/*props.user.whitelisted ? <SelectIndex /> : null*/}
                    <DriveUsed/>
                </div>}
            </div>
            <div className={styles.chatRoot}>
                <div className="w-100 d-flex" style={{
                    height: "calc(100vh - 70px)"
                }}>

                <ChatDrawerLeft
                    open={open}
                    isLoadingConversations={isLoadingConversations}
                    handleDrawer={handleDrawer}
                    conversations={conversations}
                    isOpenDefault={true}
                    onNewConversation={ () => navigate(`/chat-hr`) }
                    onConversationClick={ handleRedirectToConversation }
                    onModifyConversationTitle={ handleOpenModifyConversationTitle }
                    onDeleteConversation={ handleOpenConfirmDelete }
                />

                <div className={styles.chatContainer} style={{
                    background: props.chatTheme.bgChat
                }}>

                    {isLoadingNewConversation && (
                        <Box
                            sx={{
                                zIndex: "tooltip",
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                            }}
                        >
                            <Typography sx={{ textAlign: "center" }}>
                                {loadingConversationMsg}
                            </Typography>
                            <RotatingSpinner />
                        </Box>
                    )}
                    {isLoadingDeleteConversation && (
                        <Box
                            sx={{
                                zIndex: "tooltip",
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                            }}
                        >
                            <Typography sx={{ textAlign: "center" }}>
                                Deleting conversation...
                            </Typography>
                            <RotatingSpinner />
                        </Box>
                    )}

                    {!lastQuestionRef.current ? (
                        // View when the user is logged into the app (dont show the chat screen)
                        <div className={styles.chatEmptyState}>
                            {/*<SparkleFilled fontSize={"120px"} primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true"
                                           aria-label="Chat logo"/>*/}

                            {loading && (
                                <div>
                                    <h1 className={styles.chatEmptyStateTitle}>
                                        Welcome to Moodbit Copilot
                                    </h1>
                                    <h2 className={styles.chatEmptyStateSubtitle}>
                                        Ask any question or request, or choose from our pre-built
                                        questions, and get the answers and insights you need with
                                        ease.
                                    </h2>
                                    <p className="my-3 text-center">Starting conversation...</p>
                                </div>
                            )}
                            {/*!hrId && !loading && !isLoadingNewConversation && (
                                <div className="pb-2">
                                    <h1 className={styles.chatEmptyStateTitle}>
                                        Moodbit Copilot, your AI-HR agent can
                                    </h1>
                                    <div className={styles.chatEmptyStateTitleAnimated}>
                                        <Typed
                                            strings={[
                                                'show you the latest payroll report.',
                                                'help you design a career path for your teams.',
                                                'understand your employee attrition level, and give you a strategy to change it.',
                                            ]}
                                            typeSpeed={50}
                                            backSpeed={10}
                                            loop
                                        />
                                    </div>
                                    <h2 className={styles.chatEmptyStateSubtitle}>
                                        With Moodbit Copilot, you have a knowledgeable and reliable
                                        assistant at your fingertips, available to help you with any
                                        question or request you may have.
                                    </h2>
                                    <h2 className={styles.chatEmptyStateSubtitle}>
                                        Simply start a conversation with Moodbit Copilot and unlock
                                        a world of possibilities. Need to know the status of a
                                        hiring process? Want to generate a report on employee
                                        performance? Interested in exploring career paths for your
                                        team members? Just ask, and Moodbit Copilot will provide you
                                        with the answers you need, from your organization's data.
                                    </h2>
                                    <div className={styles.startButton}>
                                        <button
                                            className="btn btn-primary "
                                            onClick={() => newConversation()}
                                        >
                                            Start Now
                                        </button>
                                    </div>
                                </div>
                            )*/}

                                {!conversationId && <div className={styles.chatEmptyState}>
                                    <ExampleList onExampleClicked={onExampleClicked} indexName={props.indexName} />
                                </div>}
                        </div>
                    ) : (
                        // Chat view
                        <div className={styles.chatMessageStream} ref={contentRef}>
                            {
                                /** show pbi QA */
                                showQa ?
                                    <div>
                                        <PbiQaIndex question={currentMessageQa}
                                            answers={answers}
                                            setClose={() => {
                                                setShowQa(false);
                                                setCurrentMessageQa(null);
                                            }} />
                                    </div>
                                    : answers.map((answer, index) => ( // Show the chat normal view, with all the past messages (except the last one)
                                        <div key={index}>
                                            <UserChatMessage message={answer[0]} />
                                            <div className={styles.chatMessageGpt}>
                                                <Answer
                                                    key={index}
                                                    index={index}
                                                    answer={answer[1]}
                                                    answers={answers}
                                                    isLoadingInsight={(answers.length - 1 === index) ? isLoadingInsight : false} // only on the last interaction send the actual loading flag
                                                    isLoadingFollowup={(answers.length - 1 === index) ? isLoadingFollowup : false} // only on the last interaction send the actual loading flag
                                                    makeApiRequest={makeApiRequest}
                                                    isSelected={
                                                        selectedAnswer === index &&
                                                        activeAnalysisPanelTab !== undefined
                                                    }
                                                    onCitationClicked={(c, name) =>
                                                        onShowCitation(c, name, index)
                                                    }
                                                    onThoughtProcessClicked={() =>
                                                        onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)
                                                    }
                                                    onSupportingContentClicked={() =>
                                                        onToggleTab(
                                                            AnalysisPanelTabs.SupportingContentTab,
                                                            index
                                                        )
                                                    }
                                                    newFollowup={() => newFollowup(answer[0])}
                                                    onFollowupQuestionClicked={(q) => makeApiRequest(q)}
                                                    showFollowupQuestions={
                                                        useSuggestFollowupQuestions &&
                                                        answers.length - 1 === index
                                                    }
                                                    onSetShowQa={() => {
                                                        setShowQa(true)
                                                        setCurrentMessageQa(answer[0])
                                                    }}
                                                    question={answer[0]}
                                                    setClose={() => {
                                                        setShowQa(false);
                                                        setCurrentMessageQa(null);
                                                    }}
                                                    toggleActionPlan={(ans) => getActionPlan(ans)}
                                                    updateRateAnswer={(rate) => updateRateAnswer(index, answer[1].id, rate)}
                                                />
                                            </div>
                                        </div>
                                    ))
                            }
                            {/* To handle the last meesage */}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    {/*<div className={styles.chatMessageGptMinWidthLoading}>*/}
                                    {/*    <AnswerLoading />*/}
                                    {/*</div>*/}
                                    <ThinkingIndicator/>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError
                                            error={error.toString()}
                                            onRetry={() => makeApiRequest(lastQuestionRef.current, false)}
                                        />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}
                    {/* Input to write the question */}
                    { (
                        <div className={styles.chatInput}>
                            <QuestionInput
                                clearOnSend
                                placeholder="Type a message"
                                disabled={isLoading || typing}
                                answers={answers}
                                onSend={(question) => makeApiRequest(question)}
                                setDriveFileId={setDriveFileId}
                                driveFileId={driveFileId}
                            />
                        </div>
                    )}
                </div>
                </div>


                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        fileName={fileName}
                        onActiveTabChanged={(x) => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                {
                    <Panel
                        headerText="Configure answer generation"
                        isOpen={isConfigPanelOpen}
                        isBlocking={false}
                        onDismiss={() => setIsConfigPanelOpen(false)}
                        closeButtonAriaLabel="Close"
                        onRenderFooterContent={() => (
                            <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>
                                Close
                            </DefaultButton>
                        )}
                        isFooterAtBottom={true}
                    >
                        <TextField
                            className={styles.chatSettingsSeparator}
                            defaultValue={promptTemplate}
                            label="Override prompt template"
                            multiline
                            autoAdjustHeight
                            onChange={onPromptTemplateChange}
                        />

                        <SpinButton
                            className={styles.chatSettingsSeparator}
                            label="Retrieve this many documents from search:"
                            min={1}
                            max={50}
                            defaultValue={retrieveCount.toString()}
                            onChange={onRetrieveCountChange}
                        />
                        <TextField
                            className={styles.chatSettingsSeparator}
                            label="Exclude category"
                            onChange={onExcludeCategoryChanged}
                        />
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useSemanticRanker}
                            label="Use semantic ranker for retrieval"
                            onChange={onUseSemanticRankerChange}
                        />
                        <Checkbox
                            className={styles.chatSettingsSeparatr}
                            checked={useSemanticCaptions}
                            label="Use query-contextual summaries instead of whole documents"
                            onChange={onUseSemanticCaptionsChange}
                            disabled={!useSemanticRanker}
                        />
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useSuggestFollowupQuestions}
                            label="Suggest follow-up questions"
                            onChange={onUseSuggestFollowupQuestionsChange}
                        />
                    </Panel>
                }
                    <ConfirmDelete
                        open={isConfirmDeleteOpen}
                        onConfirm={handleDeleteConversation}
                        onClose={handleCloseConfirmDelete}
                    />
                    <ModifyConversationTitle
                        open={isModifyConversationTitleOpen}
                        onSubmit={handleModifyConversationTitle}
                        onClose={handleCloseModifyConversationTitle}
                    />
                {/** show action plan*/}
                <ActionPlan open={openActionPlan} toggleDrawer={toggleActionPlan} actionPlan={actionPlan} loading={loadingActionPlan} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log('state app:', state)
    return {
        user: state.users.user,
        indexName: state.agentHrs.index,
        containerName: state.agentHrs.container,
        indexObj: state.agentHrs.indexObj,
        chatTheme: state.agentHrs.chatTheme,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIndexName: (index) => dispatch(setIndex(index)),
        setIndexObj: (index) => dispatch(setIndexObj(index)),
        setContainer: (container) => dispatch(setContainer(container)),
        setCopilotTheme: (theme) => dispatch(setCopilotTheme(theme)),
        getUser: (email) => dispatch(getUserWithEmailThunk(email))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentHrChatIndex)
